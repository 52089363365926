/* FILTER SEARCH CONTAINER */
.filter-search {
  @apply flex pt-3 pb-2 justify-between 2xs:max-sm:pt-0;
}
.filterSearchWrap {
  @apply 2xs:max-sm:whitespace-nowrap 2xs:max-sm:overflow-x-auto;
}
.tableSearchInput {
  @apply focus-visible:border-dark-blue w-full text-dark-blue focus-visible:outline-0 border-2 border-dark-blue rounded h-9 pl-2 pr-5 placeholder:text-dark-blue placeholder:text-sm placeholder:font-poppins 2xs:max-md:px-1 2xs:max-md:h-6 2xs:max-md:w-24 2xs:max-md:placeholder:text-xs 2xs:max-md:text-xs;
}
.tableSearchImg {
  @apply absolute right-3 top-3 w-3 2xs:max-md:top-2 2xs:max-md:right-2;
}
.main-filterBtn {
  @apply hover:bg-white hover:border-dark-blue hover:text-dark-blue;
}
.filterDropdownContainer {
  @apply text-black text-left border-1 whitespace-nowrap border-lg4 hover:border-light-gray-2 h-full hover:bg-light-gray-2 transition-all ease-in-out duration-300 px-2 py-1 mx-1 rounded capitalize font-figtree relative 2xs:max-md:text-xs 2xs:max-md:h-auto 2xs:max-md:px-1 2xs:max-md:mx-0.5;
}
.filterDropdownArr {
  @apply font-poppins absolute right-2 2xs:max-md:right-1 top-1 cursor-pointer font-semibold;
}
.filterSearchDropdownWrap {
  @apply bg-white rounded absolute border-1 mt-2 mx-1 py-2 border-lg2 left-0 z-9999 shadow-sh-1 transition-all ease-in-out duration-300 w-44;
}
.filterSearchDropdownInput {
  @apply focus-visible:border-dark-blue w-full text-dark-blue focus-visible:outline-0 border-1 border-dark-blue rounded h-9 pl-2 pr-5 placeholder:text-dark-blue placeholder:text-sm placeholder:font-poppins;
}
.filterSearchIcon-d {
  @apply absolute right-3 top-5 w-3;
}
.filterDropdown-item {
  @apply px-2 flex w-full items-baseline hover:bg-sec-blue hover:text-white transition-all ease-in-out duration-300 capitalize font-figtree;
}
.filterSearchErr {
  @apply py-1 px-2 font-figtree text-red-400;
}
.filterCheckInputLabel {
  @apply pl-2 cursor-pointer py-1 flex w-full;
}
/* FILTER SEARCH CONTAINER END*/

/* DIALOG BOX */
.dialogBoxWrap {
  @apply text-center bg-white max-w-lg w-11/12 py-16 rounded-2xl z-9999;
}
.animateSpin {
  @apply animate-spin h-14 w-14 m-auto mb-4;
}
.dialogBoxTitle {
  @apply text-xl text-light-black font-figtreeBold pb-5;
}
.dialogBoxMsg {
  @apply text-black font-figtree text-lg 2xs:max-sm:text-base px-5;
}
.dialogBoxPhone {
  @apply text-black font-figtree text-lg;
}
.dialogCloseBtn {
  @apply absolute top-3 right-3 cursor-pointer w-4;
}
/* DIALOG BOX END */

.textInputLabel-c {
  @apply text-sm font-bold font-figtreeBold;
}
.textInput-c {
  @apply w-full bg-white mt-1 py-2 px-3 rounded-lg border-2 placeholder:text-light-blue font-figtree;
}
.checkInput-label {
  @apply underline text-light-blue text-sm pl-1 font-medium font-inter;
}

/* VIEW DIALOG UI */
.pictureListWrap,
.visit-sign-container {
  @apply p-4 rounded-md border border-lg5 shadow-md mt-4;
}
.uploadImgTxt {
  @apply text-dark-blue font-figtree font-semibold 2xs:max-lg:text-sm py-2 relative w-full text-left;
}
.uploadImg {
  @apply w-1/5 h-20 2xs:max-sm:w-3/6 2xs:max-sm:h-32;
}
.imgSliderContent {
  @apply max-w-xl w-11/12 z-9999 bg-white rounded py-2 px-3;
}
.imgSliderHeading {
  @apply text-center text-dark-blue font-figtreeMedium text-lg py-3;
}
.prev-next-btn {
  @apply absolute top-1/2 translate-y-50 text-3xl text-lg4 hover:text-lb4;
}
.sliderImg {
  @apply w-full h-96 m-auto object-contain;
}
.currentSliderImgInd {
  @apply text-center text-dark-blue font-figtreeMedium text-lg py-3;
}
.sliderCloseIco {
  @apply absolute top-3 right-3 cursor-pointer w-4;
}
.img-dialog-container {
  @apply max-w-xl w-full bg-white rounded py-2 px-2 relative z-9999;
}
.img-dialog {
  @apply w-full h-96 m-auto object-contain;
}
.img-dialog-wrap {
  @apply relative pt-7 px-2 pb-2;
}
.img-dialog-closeIco {
  @apply absolute top-0 right-0 cursor-pointer w-4;
}
.dialog-heading {
  @apply text-dark-blue font-figtree text-2xl text-center font-semibold 2xs:max-sm:text-xl pb-4;
}
.detail-row-wrap {
  @apply border-b-2 border-lg4 flex justify-between py-1;
}
.detail-row-title {
  @apply font-figtree font-semibold text-dark-blue 2xs:max-sm:text-sm pr-10;
}
.detail-row-val {
  @apply font-poppins  font-semibold 2xs:max-sm:text-sm text-15;
}
.view-dialog-content {
  @apply bg-white z-9999 max-w-2xl w-11/12 2xs:max-sm:w-95% 2xs:max-md:px-4 2xs:max-md:py-4 pb-12 pt-7 px-8 rounded-2xl;
}
.officer-dialog-content {
  @apply bg-white z-9999 max-w-xl w-11/12 2xs:max-sm:w-95% 2xs:max-md:px-4 2xs:max-md:py-4 pb-12 pt-7 px-8 rounded-2xl;
}
.view-success-msg-d {
  @apply text-black font-figtree text-lg 2xs:max-sm:text-base px-5 text-center mb-3;
}
.view-dialog-content-wrap {
  @apply p-4 rounded-md border-1 border-lg5 shadow-md;
}
.view-dialog-btn-wrap {
  @apply mt-3 2xs:max-sm:px-2 justify-center;
}
.view-dialog-close {
  @apply absolute top-5 right-4 cursor-pointer w-4;
}
.visit-check-confirm {
  @apply bg-white max-w-xl w-11/12 py-16 rounded-2xl z-9999;
}
.visit-check-confirm-title {
  @apply text-center font-figtree text-xl text-lb5 px-5 pb-2;
}
.visit-check-confirmBtn-wrap {
  @apply mt-5 2xs:max-sm:mt-4;
}
.signText {
  @apply text-dark-blue font-figtree font-semibold 2xs:max-lg:text-sm py-2 relative w-full text-left;
}
.signImgWrap {
  @apply flex justify-center text-center text-dark-blue font-figtree font-semibold 2xs:max-lg:text-sm py-2;
}
.machineInfo-view--d {
  @apply text-dark-blue font-figtreeBold text-lg pb-3;
}
.machineState-view--d {
  @apply text-dark-blue font-figtreeBold text-lg pb-3 pt-5;
}
.user-view-content {
  @apply bg-white z-9999 max-w-xl w-11/12 2xs:max-sm:w-95% 2xs:max-md:px-4 2xs:max-md:py-4 pb-12 pt-7 px-12 rounded-2xl;
}
.expense-vie-content {
  @apply bg-white z-9999 max-w-2xl w-11/12 2xs:max-sm:w-95% 2xs:max-md:px-4 2xs:max-md:py-4 pb-12 pt-7 px-8 rounded-2xl;
}
.expense-view-bd {
  @apply text-blue-50 border-blue-50 border-1 text-xs ml-2 px-1;
}
.expense-action-btn-wrap {
  @apply px-108 mt-3 2xs:max-sm:px-2;
}
.expense-action-btn {
  @apply px-2 w-full 2xs:max-sm:p-1;
}
.view-sheet-container {
  @apply bg-white z-9999 max-w-2xl w-11/12 2xs:max-sm:w-95%  2xs:max-md:px-4 2xs:max-md:py-4 pb-12 pt-7 px-8 rounded-2xl;
}
.expense-tabs-btn {
  @apply text-dark-blue font-figtree font-semibold 2xs:max-lg:text-sm cursor-pointer relative p-4 w-full text-left;
}
.expense-tabs-wrap {
  @apply rounded-md border border-lg5 shadow-md mt-4;
}
.expense-tab-arr {
  @apply right-5 w-5 py-2 absolute;
}
.expense-tabs-table {
  @apply border-1 w-full border-lg6 border-collapse table-fixed;
}
.balance-updatedAt {
  @apply border border-lg6 text-db2 font-figtree py-1 px-5;
}
.expense-table-row-label {
  @apply border border-lg6 text-db2 font-figtree py-1 px-5 capitalize;
}
.expense-table-row-val {
  @apply border border-lg6 px-5 font-figtree;
}
/* VIEW DIALOG UI END */
