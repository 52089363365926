@tailwind components;

/* main table */
.table-container {
  @apply bg-white overflow-auto;
}
.table-height {
  @apply h-[calc(100vh-8em)] 2xs:max-lg:h-[calc(100vh-7em)] 2xs:max-md:h-[calc(100vh-9em)];
}
.table {
  @apply w-full table-auto text-center;
}
.table-head-row {
  @apply bg-dark-blue text-white font-figtree sticky top-0 2xs:max-md:top-0 z-99;
}
.table-head-th {
  @apply py-2 px-1 text-base font-normal sm:max-lg:text-sm 2xs:max-sm:text-xs;
}
.table-row {
  @apply border-b-light-gray border-b-bw font-figtree text-15 sm:max-lg:text-sm 2xs:max-sm:text-xs text-lb5 hover:bg-lb6;
}
.backDatedBtn {
  @apply text-blue-50 border-blue-50 border-1 text-xs ml-2 px-1;
}
.tableViewBtn {
  @apply 2xs:max-lg:text-xs 2xs:max-lg:w-16 2xs:max-lg:p-1 bg-button-bg text-white rounded-md p-2 w-24 hover:bg-none hover:border-dark-blue border-2 h-10 hover:text-lb5 transition ease-in-out duration-700 hover:font-semibold;
}
.status-data {
  @apply font-figtree font-bold p-3;
}
.dataCheckWrap {
  @apply p-3 flex justify-center mt-1;
}
.allCheckBox {
  @apply w-4 h-4 border-2 border-white rounded;
}
.dataCheckedBtn {
  @apply m-1 2xs:max-lg:m-2sml 2xs:max-sm:w-5 2xs:max-sm:h-5;
}
.dataCheckedIcon {
  @apply w-7 h-7 sm:max-lg:w-6 sm:max-lg:h-6 2xs:max-sm:w-5 2xs:max-sm:h-5;
}

/* main table end */

/* expense sheet and table */
.sheet-container {
  @apply text-center bg-white max-w-2xl w-11/12 py-16 rounded-2xl z-9999;
}
.sheetHeading {
  @apply text-dark-blue text-xl font-bold font-figtree pb-8;
}
.sheet-table {
  @apply w-full text-left text-sm font-poppins border-1 border-lg6;
}
.sheetTableHeadRow {
  @apply text-dark-blue bg-lg5 sticky top-0;
}
.sheetTableTh {
  @apply p-2 border border-lg6 text-sm font-semibold tracking-wide text-center;
}
/* expense sheet and table end */

.noDataDisplayContainer {
  @apply text-center absolute w-full bg-white;
}
/* user actions box  */
.actionsWrap--u {
  @apply absolute bg-white right-5 shadow-sh-1 grid w-52 z-10 border-lg5 border-1;
}
.actionLink--u {
  @apply py-1 px-2 border-b-2 border-lg5 flex items-center hover:bg-lb6;
}
.actionLinkText--u {
  @apply font-figtreeMedium text-dark-blue pl-2;
}
