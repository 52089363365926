* {
  box-sizing: border-box;
}
html,
body,
#root {
  height: 100%;
}
img {
  user-drag: none; /* Standard */
  -webkit-user-drag: none; /* Safari and Chrome */
}
